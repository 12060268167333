import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Post = styled.li`
  position: relative;
  border-bottom: 0px solid ${props => props.theme.colors.primary};
  border-radius: 0;
  transition: background 0.2s;
  padding: 0.25rem;
  margin-bottom: 1.5rem;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.highlight};
  flex: 0 0 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    border-bottom: none;
    flex: 0 0 48%;
    margin-bottom: 3rem;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex: 0 0 32%;
    &.two-columns {
      flex: 0 0 50%;
    }
    &.three-columns {
      flex: 0 0 30%;
      margin-bottom: 3rem;
    }
  }
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    color: ${props => props.theme.colors.base};
    text-decoration: none;
    .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 100%;
      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        padding-bottom: 100%;
      }
    }
  }
`

const Title = styled.h2`
  color: ${props => props.theme.colors.secondary};
  font-size: 1.1em;
  font-family: ${props => props.theme.fonts.title};
  font-weight: 800;
  line-height: 1.15;
  text-align: left;
  margin-bottom: 1rem;
  span {
    display: block;
    font-size: 0.9em;
    font-family: ${props => props.theme.fonts.text};
    font-weight: normal;
    // color: ${props => props.theme.colors.highlight};
  }
`

const InfoBox = styled.div`
  padding: 0.7rem;
  text-align: left;
  font-size: 1.1em;
  p {
    line-height: 1.4;
    font-size: 1em;
  }
  a {
    color: ${props => props.theme.colors.highlight};
    &:hover {
      color: ${props => props.theme.colors.secondary};
      transition: color 0.3s;
      -webkit-transition: color 0.3s;
      -moz-transition: color 0.3s;
      will-change: color:
    }
  }
`

const CardMannschaft = props => {
  return (
    <Post className={props.columns}>
      <div activelassname="active">
        <Img fluid={props.data.image.fluid} backgroundColor="#eeeeee" />
        <InfoBox>
          <Title>
            {props.data.firstname} {props.data.lastname}
            <span>{props.data.occupation}</span>
          </Title>
          <p>
            <a href={`mailto:${props.data.email}`}>
              <i className="icon envelope" /> {props.data.email}
            </a>
          </p>
          <p>
            <a href={`tel:${props.data.phone}`}>
              <i className="icon phone" /> {props.data.phone}
            </a>
          </p>
          <p>
            <a
              href={`https://wa.me/${props.data.phone
                .replace('+', '')
                .replace(/\s/g, '')}`}
            >
              <i className="icon whatsapp" /> Whatsapp
            </a>
          </p>
          <p>
            <a
              href={props.data.vCard.file.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="icon address book" /> vCard herunterladen
            </a>
          </p>
        </InfoBox>
      </div>
    </Post>
  )
}

export default CardMannschaft
