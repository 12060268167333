/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import { graphql } from 'gatsby'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import TextContainer from '../components/TextContainer'
import PageTitle from '../components/PageTitle'
import PageBody from '../components/PageBody'
import RibbonComponent from '../components/Ribbon'
import CardList from '../components/CardList'
import CardMannschaft from '../components/CardMannschaft'
import SEO from '../components/SEO'

// eslint-disable-next-line react/prop-types
const Kontakt = ({ data }) => {
  const postNode = {
    // eslint-disable-next-line react/prop-types
    title: `${data.contentfulPage.title} - ${config.siteTitle}`,
  }

  // eslint-disable-next-line react/prop-types
  const { body, title, subtitle } = data.contentfulPage

  // eslint-disable-next-line react/prop-types
  const mappedMannschaft = data.allContentfulTeamMitglieder.edges.map(
    (el, i) => (
      <CardMannschaft
        key={`mannschaft-card-${i}`}
        data={el.node}
        columns="three-columns"
      />
    )
  )

  return (
    <Layout>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <TextContainer>
        <RibbonComponent
          text="Klick hier und lass dich überraschen!"
          link={data.contentfulPage.ribbonLink || '/preise'}
          image={data.contentfulPage.ribbon}
          color="green"
          isCentered
          unclickable
        />
      </TextContainer>

      <PageTitle particles backgroundColor={'transparent'} />

      <Container>
        <TextContainer>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </TextContainer>
        {body && <PageBody body={body} />}
      </Container>

      <Container>
        <CardList columns="three-columns">{mappedMannschaft}</CardList>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulTeamMitglieder(sort: { fields: reihenfolge, order: ASC }) {
      edges {
        node {
          id
          reihenfolge
          vCard {
            file {
              url
            }
            description
            title
          }
          whatsapp
          phone
          occupation
          firstname
          lastname
          image {
            fluid {
              srcSetWebp
              srcWebp
              srcSet
              src
              sizes
              base64
              aspectRatio
            }
          }
          firstname
          email
        }
      }
    }
    contentfulPage(slug: { eq: "mannschaft" }) {
      id
      title
      subtitle
      ribbon {
        fluid {
          base64
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          aspectRatio
        }
      }
      ribbonLink
      headerImage {
        id
        fluid(
          quality: 100
          maxWidth: 1920
          resizingBehavior: NO_CHANGE
          toFormat: NO_CHANGE
        ) {
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          base64
          aspectRatio
          ...GatsbyContentfulFluid
        }
      }
      body {
        json
      }
    }
  }
`

export default Kontakt